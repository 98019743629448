.pre-line {
  width: 3px;
  background-color: #00688d;
  height: 3em;
  border-radius: 1.5px;
}

.selected-color {
  background-color: #e9ebef;
}

.unselected-color {
  background-color: #ffffff;
}
