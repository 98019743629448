.fieldContainer {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}

.field {
  margin-right: 10px;
  margin-bottom: 10px;
}
