.options {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}

.option {
  margin-right: 10px;
  margin-bottom: 10px;
}
