.confirmedAnswerContainer {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  align-items: flex-start;
}

.confirmedAnswer {
  margin-right: 5px;
  margin-bottom: 5px;
}
